<template>
    <div class="px-2">
		<BHLoading :show="loading" />
		<div class="ant-col ant-form-item-label">
			<label>
				<span>Choose an instance to pull event data</span>
			</label>
		</div>
		<a-select v-model="event.instance" :options="instances" />
		<template v-if="event.instance && event.instance.length">
			<div class="mt-3 ant-col ant-form-item-label">
				<label>
					<span>Select which event(s) to show</span>
				</label>
			</div>
			<a-select
				mode="multiple"
				v-model="event.events"
				style="width: 100%"
			>
				<a-select-option v-for="(evt,evtI) in events" :key="evt+evtI" :value="evt.id">
					<b>{{evt.title}}</b> <span style="font-style: italic;"> from {{evt.assignTo.firstName}} {{evt.assignTo.lastName}}</span>
				</a-select-option>
			</a-select>
		</template>
	</div>
</template>

<script>

import BHLoading from 'bh-mod/components/common/Loading'

export default {
    components:{BHLoading},
    props:{
        value:{
            type:Object,
            required:true,
            default: () => {}
        },
    },
    data(){
        return {
            event:{
				instance:'',
				events:[]
			},
			loading:false,
			instances:[],
			events:[]
        }
    },
    computed:{

    },
    methods:{
        
    },
    watch:{
        event:{
            handler(val,oldVal){
                return this.$emit('input', val)
            },
			deep:true
        },
		'event.instance':{
            handler(val){
				if (val && val.length){
					this.loading = true;
					this.$api.get(`/event-groups/${val}`).then(({data}) => {
						this.events = data
						this.loading = false;
					})
				}
            },
            deep:true
        },
    },
    created(){
        this.$api.get(`/instances/:instance`).then( ({data}) => {
			let {children = [],...instance} = data
			let options = [instance,...children]
			options = options.map( ({id,name,api}) => ({
				label:name,
				value:id
			}))
			this.instances = options

			if (this.value){
				this.event = this.value
			}
		})
    },
    mounted(){
        // if (!this.value) this.instance = this.instances[0].value
    }
}
</script>

<style scoped lang="scss">

</style>